
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TechnologyList from "@/ComponentsSystem/Ht-Tr-technology/TechnologyList.vue";

export default defineComponent({
  name: "projects",
  components: {
    TechnologyList,
  },
  data(): {
    DateType: string;
    startDate: string;
    endDate: string;
  } {
    return {
      DateType: "",
      startDate: "",
      endDate: "",
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("模擬報告清單", ["熱處理技術服務雲系統"]);
    });
  },
});
